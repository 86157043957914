import { render, staticRenderFns } from "./Delivery.vue?vue&type=template&id=5d2b1f66&scoped=true"
import script from "./Delivery.vue?vue&type=script&lang=js"
export * from "./Delivery.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d2b1f66",
  null
  
)

export default component.exports